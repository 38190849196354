import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextField } from "@mui/material";
import { Space, Table } from "antd";
import Swal from "sweetalert2";
import "react-datepicker/dist/react-datepicker.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import qs from "qs";
import CsvCampaignData from "./CsvCampaignData";

const getRandomuserParams = (params) => ({
  results: params.pagination?.pageSize,
  page: params.pagination?.current,
  ...params,
});

const CampaignData = () => {
  const [data, setData] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");

  const [total, setTotal] = useState(0);
  const [editContactId, setEditContactId] = useState(null);

  const [loading, setLoading] = useState(false);

  const [selectedRowKeys, setSelectedRowKeys] = useState([]);

  const [contact, setContact] = useState({
    name: "",
    whatsappMobile: "",
    email: "",
    campaignid: "",
  });

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const handleEditContact = (_id) => {
    let selectedContact;

    selectedContact = data.find((contact) => contact._id === _id);

    if (selectedContact) {
      setEditContactId(_id);
      setContact({
        name: selectedContact.name,
        email: selectedContact.email,
        whatsappMobile: selectedContact.whatsappMobile,
        campaignid: selectedContact.campaignid,
      });
    }
  };

  const fetchContactData = async () => {
    try {
      const params = {
        startreq:
          (tableParams.pagination.current - 1) *
          tableParams.pagination.pageSize,
        limit: tableParams.pagination.pageSize,
      };

      const response = await axios.get(
        `/api/campaigndata/display_campaign_data?${qs.stringify(params)}`
      );
      console.log(response.data.contacts);
      setData(response.data.contacts);
      setTotal(response.data.totalCount);
      console.log(response.data.totalPages);
    } catch (error) {
      console.error("Error fetching contact data:", error);
    }
  };

  useEffect(() => {
    fetchContactData();
  }, [JSON.stringify(tableParams)]);

  const handleChangeContact = (e) => {
    const { name, value } = e.target;
    setContact((prevContact) => {
      const updatedContact = {
        ...prevContact,
        [name]: value,
      };
      return updatedContact;
    });
  };

  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });

    // `dataSource` is useless since `pageSize` changed
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Check for errors before submitting
    try {
      setLoading(true);
      const formData = { ...contact };

      if (editContactId) {
        await axios
          .put(`/api/campaigndata/edit_campaigndata/${editContactId}`, formData)
          .then((res) => {
            setContact({
              name: "",
              email: "",
              whatsappMobile: "",
              campaignid: "",
            });

            Swal.fire({
              title: "Contact Updated successfully!",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false, // No "Saved" button
              timer: 2000,
            }).then(() => {
              // Fetch the updated template data
              fetchContactData();
            });
          })
          .catch((error) => {
            let errorMessage = "Error updating Contact. Please try again.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            Swal.fire("Error", errorMessage, "error");
            console.log(error);
          });
      } else {
        await axios
          .post("/api/campaigndata/create_campaign_data", formData)
          .then((response) => {
            setContact({
              name: "",
              email: "",
              whatsappMobile: "",
              campaignid: "",
            });

            Swal.fire({
              title: "Contact Added successfully!",
              icon: "success",
              showCancelButton: false,
              showConfirmButton: false, // No "Saved" button
              timer: 1000,
            }).then(() => {
              // Fetch the updated template data
              fetchContactData();
            });
          })
          .catch((error) => {
            let errorMessage = "Error creating Contact. Please try again.";
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            Swal.fire("Error", errorMessage, "error");
            console.log(error);
          });
      }
    } catch (error) {
      console.error("Error submitting contact data:", error);
    } finally {
      // Ensure loading state is cleared even in case of error
      setLoading(false);
    }
  };

  useEffect(() => {
    // Clear loading state when component unmounts
    return () => {
      setLoading(false);
    };
  }, []);

  const handleDeleteCampaign = async (_id) => {
    // Show SweetAlert2 confirm dialog
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete it!",
    });

    if (confirmed.isConfirmed) {
      // Show a loading spinner while holding the action
      Swal.fire({
        title: "Deleting...",
        allowOutsideClick: false,
        allowEscapeKey: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        },
      });
      // Delete contact with the given ID
      axios
        .delete(`/api/campaigndata/deletebyid_campaigndata/${_id}`)
        .then((res) => {
          fetchContactData();
          Swal.fire("Deleted!", "Contact deleted successfully!", "success");
        })
        .catch((error) => {
          Swal.fire("Error", "Something went wrong!", "error");
        });
    }
  };

  const columns = [
    //Actions
    {
      title: "Action",
      fixed: "left",
      key: "action",
      align: "center",
      width: 150,
      render: (text, record) => (
        <Space size="middle">
          <button
            type="button"
            className="edit-btn"
            data-bs-toggle="modal"
            data-bs-target="#myModal"
            onClick={() => handleEditContact(record._id)}
          >
            <i className="ri-eye-line"></i>
          </button>

          <button
            onClick={() => handleDeleteCampaign(record._id)}
            className="delete-btn"
          >
            <i className="ri-delete-bin-fill"></i>
          </button>
        </Space>
      ),
    },
    //Name
    {
      title: "Campaign ID",
      dataIndex: "campaignid",
      key: "campaignid",
      align: "center",
      ellipsis: true,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      align: "center",
      ellipsis: true,
    },

    //Email
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
      align: "center",
      ellipsis: true,
    },
    //Whatsapp Number
    {
      title: "WhatsApp Number",
      dataIndex: "whatsappMobile",
      key: "whatsappMobile",
      align: "center",
      ellipsis: true,
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (selectedKeys) => setSelectedRowKeys(selectedKeys),
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleAddContactButtonClick = () => {
    setEditContactId(null);

    setContact({
      name: "",
      email: "",
      whatsappMobile: "",
      campaignid: "",
    });
  };

  const [selectedFile, setSelectedFile] = useState(null);
  const [csvHeaders, setCsvHeaders] = useState([]);
  const [showHeadersModal, setShowHeadersModal] = useState(false);

  const handleFileSelection = (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);

    // console.log(file);

    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        const content = event.target.result;
        // Parse the CSV content and extract headers
        const lines = content.split("\n");
        if (lines.length > 0) {
          const headers = lines[0].split(",");
          setCsvHeaders(headers);
          setShowHeadersModal(true);
        }
      };
      reader.readAsText(file);
    }
  };

  const handleCloseModal = () => {
    setShowHeadersModal(false);
  };

  const getDataSource = () => {
    return data;
  };

  const handleSearch = (e) => {
    e.preventDefault();
    setSearchQuery(e.target.value);
  };

  // Delete All Functionality
  const handleDeleteAllContacts = async () => {
    const confirmed = await Swal.fire({
      title: "Are you sure?",
      text: "This action will delete all data. This cannot be undone.",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, delete all!",
    });

    if (confirmed.isConfirmed) {
      try {
        const response = await axios.delete(
          "/api/campaigndata/delete_all_campaign_data"
        );
        if (response) {
          Swal.fire(
            "Deleted!",
            "All Contacts deleted successfully!",
            "success"
          );
          fetchContactData();
        } else {
          console.error("Failed to delete all data.");
          Swal.fire("Error", "Failed to delete all data.", "error");
        }
      } catch (error) {
        console.error("An error occurred:", error);
        Swal.fire(
          "Error",
          "An error occurred while deleting contacts.",
          "error"
        );
      }
    }
  };

  return (
    <div>
      <div className="page-content w-100">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="page-title-box d-sm-flex align-items-center justify-content-between">
                <h4 className="mb-sm-0 py-3">Campaign Data </h4>
                <div>
                  <h4 className="mb-sm-0 py-3">Total Contacts : {total}</h4>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-xxl-12">
              <div id="contactList" className="card">
                <div className="card-header py-3">
                  <div className="d-flex align-items-center flex-wrap gap-2">
                    <div className="d-flex flex-wrap flex-grow-1 gap-2">
                      <button
                        type="button"
                        className="btn btn-primary add-btn"
                        onClick={handleAddContactButtonClick}
                        data-bs-toggle="modal"
                        data-bs-target="#exampleModal"
                      >
                        <i className="ri-add-fill me-1 align-bottom"></i> Add
                        Campaign
                      </button>
                      {/* <form
                        className="d-flex"
                        onSubmit={(e) => e.preventDefault()}
                      >
                        <input
                          className="form-control me-2"
                          type="search"
                          id="search"
                          placeholder="Search"
                          aria-label="Search"
                          value={searchQuery}
                          onChange={handleSearch}
                        />
                      </form> */}

                      {/* Add Contacts Modal */}
                      <div
                        className="modal fade"
                        id="exampleModal"
                        tabIndex="-1"
                        aria-labelledby="exampleModalLabel"
                        aria-hidden="true"
                      >
                        <div className="modal-dialog modal-dialog-scrollable">
                          <div className="modal-content">
                            <div className="modal-header">
                              <h5
                                className="modal-title"
                                id="exampleModalLabel"
                              >
                                Add Campaign Data
                              </h5>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                                aria-label="Close"
                              ></button>
                            </div>
                            <div className="modal-body">
                              <form onSubmit={handleSubmit}>
                                <div>
                                  <TextField
                                    color="primary"
                                    size="small"
                                    fullWidth
                                    label="Enter Name"
                                    name="name"
                                    value={contact.name}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="text"
                                    required
                                    autoFocus
                                  />
                                </div>
                                <div>
                                  <TextField
                                    size="small"
                                    fullWidth
                                    label="Enter Email"
                                    name="email"
                                    value={contact.email}
                                    onChange={handleChangeContact}
                                    margin="normal"
                                    type="email"
                                    required
                                  />
                                </div>
                                <div>
                                  <PhoneInput
                                    inputClass="phone-input-className w-100"
                                    country={"in"}
                                    value={contact.whatsappMobile}
                                    onChange={(value, data, event) => {
                                      if (event && event.target) {
                                        handleChangeContact(event);
                                      }
                                    }}
                                    inputProps={{
                                      name: "whatsappMobile",
                                      required: true,
                                      placeholder: "Enter Whatsapp Number",
                                    }}
                                    dropdownClass="custom-dropdown-className"
                                    required
                                  />
                                </div>

                                <div>
                                  <TextField
                                    size="small"
                                    margin="normal"
                                    fullWidth
                                    label="Campaign ID"
                                    name="campaignid"
                                    value={contact.campaignid}
                                    onChange={handleChangeContact}
                                    autoComplete="campaignid"
                                    autoFocus
                                  />
                                </div>

                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    className="btn btn-secondary"
                                    data-bs-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    data-bs-dismiss="modal"
                                    className="btn btn-primary"
                                  >
                                    Add Campaign Data
                                  </button>
                                </div>
                              </form>
                            </div>
                            {loading && (
                              <div className="loading-indicator">
                                Loading...
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="import-button">
                      <label className="btn btn-success add-btn">
                        <i className="ri-add-fill me-1 align-bottom"></i> Import
                        CSV
                        <input
                          type="file"
                          accept=".csv"
                          style={{ display: "none" }}
                          onChange={handleFileSelection}
                        />
                      </label>
                      {showHeadersModal && (
                        <CsvCampaignData
                          file={selectedFile}
                          csvHeaders={csvHeaders}
                          data={data}
                          onClose={handleCloseModal}
                          fetchContactData={() => fetchContactData()}
                        />
                      )}
                    </div>
                    <div>
                      <button
                        className="btn btn-outline-info"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                        // onClick={() => {
                        //   if (hasSelected) {
                        //     setIsEditingSelected(true);
                        //     setSelectedContactIds(selectedRowKeys);
                        //   } else {
                        //     setIsEditingSelected(false);
                        //   }
                        // }}
                      >
                        <i className="ri-more-2-fill"></i>
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                        <li>
                          <a
                            className="dropdown-item"
                            onClick={handleDeleteAllContacts}
                            href="#"
                          >
                            Delete All
                          </a>
                        </li>
                        <li>
                          <hr className="dropdown-divider" />
                        </li>
                      </ul>
                    </div>

                    {/* View & Edit Modal */}
                    <div className="flex-shrink-0">
                      <div
                        className="sidemodal slide-from-right modal fade"
                        id="myModal"
                        tabIndex="-1"
                      >
                        <div className="side-modal-dialog modal-dialog modal-dialog-scrollable">
                          <div className="side-modal-content modal-content">
                            <div className="modal-header">
                              <h4 className="modal-title">{contact.name}</h4>
                              <button
                                type="button"
                                className="btn-close"
                                data-bs-dismiss="modal"
                              ></button>
                            </div>
                            <form onSubmit={handleSubmit}>
                              <div className="modal-body scrollable-modal-body">
                                <section className="qualification section">
                                  <div className="section__title">
                                    <img
                                      width={85}
                                      height={85}
                                      style={{ borderRadius: "50%" }}
                                      src="https://confidentialcontent.s3.eu-west-1.wasabisys.com/wateam/kz734vks.png"
                                      alt=""
                                    />
                                  </div>
                                  {/* <span className="section__subtitle">Name</span> */}
                                  <div className="qualification__container container">
                                    <div className="qualification__tabs mb-3">
                                      <div
                                        className={
                                          toggleState === 1
                                            ? "qualification__button qualification__active button-flex"
                                            : "qualification__button button-flex"
                                        }
                                        onClick={() => toggleTab(1)}
                                      >
                                        <h6>General Details</h6>
                                      </div>
                                      {/* <div
                                        className={
                                          toggleState === 2
                                            ? "qualification__button qualification__active button-flex"
                                            : "qualification__button button-flex"
                                        }
                                        onClick={() => toggleTab(2)}
                                      >
                                        <h6 disabled>Custom Details</h6>
                                      </div> */}
                                    </div>

                                    <div className="qualification__sections">
                                      <div
                                        className={
                                          toggleState === 1
                                            ? "qualification__content qualification__content-active"
                                            : "qualification__content"
                                        }
                                      >
                                        <div className="qualification__data">
                                          <div>
                                            <TextField
                                              color="primary"
                                              size="small"
                                              fullWidth
                                              label="Enter Name"
                                              name="name"
                                              value={contact.name}
                                              onChange={handleChangeContact}
                                              margin="normal"
                                              type="text"
                                              required
                                              autoFocus
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              label="Enter Email"
                                              name="email"
                                              value={contact.email}
                                              onChange={handleChangeContact}
                                              margin="normal"
                                              type="email"
                                              required
                                            />
                                          </div>
                                          <div>
                                            <TextField
                                              size="small"
                                              fullWidth
                                              margin="normal"
                                              label="Whatsapp Number"
                                              name="whatsappMobile"
                                              value={contact.whatsappMobile}
                                              onChange={handleChangeContact}
                                              type="tel"
                                              required
                                              autoFocus
                                            />
                                          </div>

                                          <div>
                                            <TextField
                                              size="small"
                                              margin="normal"
                                              fullWidth
                                              label="Campaign ID"
                                              name="campaignid"
                                              value={contact.campaignid}
                                              onChange={handleChangeContact}
                                              autoComplete="campaignid"
                                              autoFocus
                                            />
                                          </div>
                                        </div>
                                      </div>

                                      <div
                                        className={
                                          toggleState === 2
                                            ? "qualification__content qualification__content-active"
                                            : "qualification__content"
                                        }
                                      ></div>
                                    </div>
                                  </div>
                                </section>
                              </div>

                              {/*<!-- Modal footer --> */}
                              <div className="modal-footer fixed-footer">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  data-bs-dismiss="modal"
                                >
                                  Close
                                </button>
                                <button
                                  type="submit"
                                  data-bs-dismiss="modal"
                                  className="btn btn-primary"
                                >
                                  {editContactId ? "Update Leads" : "Add Leads"}
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center flex-wrap gap-2 mt-2">
                    <div className="flex-shrink-0">
                      <div className="hstack text-nowrap gap-2 align-item-center"></div>
                    </div>
                  </div>
                </div>
                <div className="card-body">
                  <Table
                    dataSource={getDataSource()}
                    columns={columns}
                    rowKey="_id"
                    // rowSelection={rowSelection}
                    pagination={{
                      ...tableParams.pagination,
                      total,
                      position: "bottomRight",
                      showSizeChanger: true,
                      pageSizeOptions: ["10", "15", "20", "50", "100"],
                    }}
                    onChange={handleTableChange}
                    // scroll={{ y: 800 }}
                    bordered
                    components={{
                      header: {
                        cell: ({ style, ...restProps }) => (
                          <th
                            {...restProps}
                            style={{
                              ...style,
                              backgroundColor: "#c6c6c6",
                              color: "black",
                              textAlign: "center",
                            }}
                          />
                        ),
                      },
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CampaignData;
