import { CloseOutlined } from '@ant-design/icons';
import React from 'react';
function useInnerClosable(closable, closeIcon, defaultClosable) {
    if (typeof closable === 'boolean') {
        return closable;
    }
    if (closeIcon === undefined) {
        return !!defaultClosable;
    }
    return closeIcon !== false && closeIcon !== null;
}
export default function useClosable(closable, closeIcon, customCloseIconRender, defaultCloseIcon = React.createElement(CloseOutlined, null), defaultClosable = false) {
    const mergedClosable = useInnerClosable(closable, closeIcon, defaultClosable);
    if (!mergedClosable) {
        return [false, null];
    }
    const mergedCloseIcon = typeof closeIcon === 'boolean' || closeIcon === undefined || closeIcon === null
        ? defaultCloseIcon
        : closeIcon;
    return [true, customCloseIconRender ? customCloseIconRender(mergedCloseIcon) : mergedCloseIcon];
}
