import { ColorFactory } from './color';
export const customizePrefixCls = 'ant-color-picker';
export const generateColor = (color) => {
    if (color instanceof ColorFactory) {
        return color;
    }
    return new ColorFactory(color);
};
export const getRoundNumber = (value) => Math.round(Number(value || 0));
export const getAlphaColor = (color) => getRoundNumber(color.toHsb().a * 100);
