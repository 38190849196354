import classNames from 'classnames';
import useMergedState from 'rc-util/lib/hooks/useMergedState';
import React, { useContext, useRef, useState } from 'react';
import genPurePanel from '../_util/PurePanel';
import { getStatusClassNames } from '../_util/statusUtils';
import { ConfigContext } from '../config-provider/context';
import useSize from '../config-provider/hooks/useSize';
import { FormItemInputContext, NoFormStyle } from '../form/context';
import Popover from '../popover';
import theme from '../theme';
import ColorPickerPanel from './ColorPickerPanel';
import ColorTrigger from './components/ColorTrigger';
import useColorState from './hooks/useColorState';
import useStyle from './style/index';
import { customizePrefixCls, generateColor } from './util';
const ColorPicker = (props) => {
    const { value, defaultValue, format, allowClear = false, presets, children, trigger = 'click', open, disabled, placement = 'bottomLeft', arrow = true, panelRender, showText, style, className, size: customizeSize, rootClassName, styles, onFormatChange, onChange, onClear, onOpenChange, onChangeComplete, getPopupContainer, autoAdjustOverflow = true, destroyTooltipOnHide, } = props;
    const { getPrefixCls, direction, colorPicker } = useContext(ConfigContext);
    const { token } = theme.useToken();
    const [colorValue, setColorValue] = useColorState(token.colorPrimary, {
        value,
        defaultValue,
    });
    const [popupOpen, setPopupOpen] = useMergedState(false, {
        value: open,
        postState: (openData) => !disabled && openData,
        onChange: onOpenChange,
    });
    const [formatValue, setFormatValue] = useMergedState(format, {
        value: format,
        onChange: onFormatChange,
    });
    const [colorCleared, setColorCleared] = useState(false);
    const prefixCls = getPrefixCls('color-picker', customizePrefixCls);
    // ===================== Form Status =====================
    const { status: contextStatus } = React.useContext(FormItemInputContext);
    // ===================== Style =====================
    const mergedSize = useSize(customizeSize);
    const [wrapSSR, hashId] = useStyle(prefixCls);
    const rtlCls = { [`${prefixCls}-rtl`]: direction };
    const mergeRootCls = classNames(rootClassName, rtlCls);
    const mergeCls = classNames(getStatusClassNames(prefixCls, contextStatus), {
        [`${prefixCls}-sm`]: mergedSize === 'small',
        [`${prefixCls}-lg`]: mergedSize === 'large',
    }, colorPicker === null || colorPicker === void 0 ? void 0 : colorPicker.className, mergeRootCls, className, hashId);
    const mergePopupCls = classNames(prefixCls, rtlCls);
    const popupAllowCloseRef = useRef(true);
    const handleChange = (data, type, pickColor) => {
        let color = generateColor(data);
        const isNull = value === null || (!value && defaultValue === null);
        if (colorCleared || isNull) {
            setColorCleared(false);
            const hsba = color.toHsb();
            // ignore alpha slider
            if (colorValue.toHsb().a === 0 && type !== 'alpha') {
                hsba.a = 1;
                color = generateColor(hsba);
            }
        }
        // Only for drag-and-drop color picking
        if (pickColor) {
            popupAllowCloseRef.current = false;
        }
        setColorValue(color);
        onChange === null || onChange === void 0 ? void 0 : onChange(color, color.toHexString());
    };
    const handleClear = () => {
        setColorCleared(true);
        onClear === null || onClear === void 0 ? void 0 : onClear();
    };
    const handleChangeComplete = (color) => {
        popupAllowCloseRef.current = true;
        onChangeComplete === null || onChangeComplete === void 0 ? void 0 : onChangeComplete(generateColor(color));
    };
    const popoverProps = {
        open: popupOpen,
        trigger,
        placement,
        arrow,
        rootClassName,
        getPopupContainer,
        autoAdjustOverflow,
        destroyTooltipOnHide,
    };
    const colorBaseProps = {
        prefixCls,
        color: colorValue,
        allowClear,
        colorCleared,
        disabled,
        presets,
        panelRender,
        format: formatValue,
        onFormatChange: setFormatValue,
        onChangeComplete: handleChangeComplete,
    };
    const mergedStyle = Object.assign(Object.assign({}, colorPicker === null || colorPicker === void 0 ? void 0 : colorPicker.style), style);
    return wrapSSR(React.createElement(Popover, Object.assign({ style: styles === null || styles === void 0 ? void 0 : styles.popup, overlayInnerStyle: styles === null || styles === void 0 ? void 0 : styles.popupOverlayInner, onOpenChange: (visible) => {
            if (popupAllowCloseRef.current) {
                setPopupOpen(visible);
            }
        }, content: React.createElement(NoFormStyle, { override: true, status: true },
            React.createElement(ColorPickerPanel, Object.assign({}, colorBaseProps, { onChange: handleChange, onChangeComplete: handleChangeComplete, onClear: handleClear }))), overlayClassName: mergePopupCls }, popoverProps), children || (React.createElement(ColorTrigger, { open: popupOpen, className: mergeCls, style: mergedStyle, color: value ? generateColor(value) : colorValue, prefixCls: prefixCls, disabled: disabled, colorCleared: colorCleared, showText: showText, format: formatValue }))));
};
if (process.env.NODE_ENV !== 'production') {
    ColorPicker.displayName = 'ColorPicker';
}
const PurePanel = genPurePanel(ColorPicker, 'color-picker', 
/* istanbul ignore next */
(prefixCls) => prefixCls, (props) => (Object.assign(Object.assign({}, props), { placement: 'bottom', autoAdjustOverflow: false })));
ColorPicker._InternalPanelDoNotUseOrYouWillBeFired = PurePanel;
export default ColorPicker;
